.single-box {
  display: block;
  text-align: center;
  background: rgb(221, 220, 220);
  border-radius: 15px;
  transition: 0.5s ease-in;
}

.single-box:hover {
  transform: translateY(-10px);
}

.img-area {
  width: 150px;
  height: 150px;
  border: 5px solid #fff;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50%;
}

.img-area img {
  width: 100%;
}

.grid-item2 {
  width: 100%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

/* test css */

.module {
  width: 80%;
  margin: 20px auto;
  border-radius: 3px;
  padding: 20px 12px;
  box-sizing: padding-box;
  position: relative;
  border-right: 3px solid #ff9616;
  border-bottom: 3px solid #ff9616;
  border-left: 3px solid #ff9616;
}

.module__top {
  cursor: move;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.module__top:before {
  border-radius: 3px 0 0 0;
  display: block;
  content: "";
  flex: 3 0 0;
  height: 1px;
  border-top: 4px solid #ff9616;
}

.module__top:after {
  border-radius: 0 3px 0 0;
  display: block;
  content: "";
  flex: 3 0 0;
  height: 1px;
  border-top: 4px solid #ff9616;
}

.top__content {
  height: 26px;
  font: 18px Helvetica;
  font-weight: bold;
  overflow: hidden;
  flex: 0 0 150px;
  margin-top: -12px;
  text-align: center;
}

.module__contents {
  padding: 20px 0px;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .grid-item2 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .grid-item2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .grid-item2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* test css */
