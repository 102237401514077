.stands-for-cover {
  background: url("../images/we-stands-for.jpg") center center fixed;
  background-repeat: no-repeat !important;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 80vh;
}

.stands-for-title {
  padding-top: 0;
  padding-bottom: 40px;
  /* margin-top: -10px; */
}

.stands-for-title-container {
  position: static;
  display: block;
  max-width: 99999px;
  margin-right: auto;
  margin-left: auto;
  float: none;
}

.stands-for-title .title-wraper {
  position: relative;
  display: block;
  max-width: 80%;
  margin-top: -70px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}

.left-shade {
  position: absolute;
  top: 0%;
  right: 100%;
  height: 200px;
}

.right-shade {
  position: absolute;
  top: 0%;
  left: 100%;
  height: 200px;
}

.title-wraper img {
  width: 100%;
}

.title-wraper h1 {
  font-size: 2.5rem;
  margin-bottom: 25px;
}

.title-description p {
  font-size: 1.3rem !important;
  line-height: 2rem;
}

.blog-para{
  font-size: 18px;
}

.post-content {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f5f5;
}

.post-container {
  position: static;
  display: block;
  max-width: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
  float: none;
}

.post-wrapper {
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0 10px 35px -3px rgb(0 0 0 / 50%);
  box-shadow: 0 10px 35px -3px rgb(0 0 0 / 50%);
  margin-top: 0;
  margin-bottom: 20px;
}

.post-link {
  position: relative;
  display: block;
  color: #fff;
  background-position: center;
  background-size: cover, auto;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
  transform: scaleX(1) scaleY(1) scaleZ(1);
}

.post-link::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.post-link:hover {
  transform: scaleX(1.15) scaleY(1.15) scaleZ(1);
}

.post-title-wraper {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.post-title {
  display: flex;
  height: 100%;
  padding: 20px 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px none #fff;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  text-shadow: 0 1px 11px rgb(0 0 0 / 50%);
}

.removeText {
  display: none;
}


.sub-menu {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 25px;
  background-color: #ffb422;
  background-image: none;
  background-size: auto;
  text-align: center;
}

.sub-menu-content {
  position: relative;
  top: -20px;
  padding: 25px 25px;
  background-color: #fff;
  text-align: center;
  -webkit-box-shadow: 0 9px 47px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 9px 47px 0 rgb(0 0 0 / 20%);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

/* .sub-menu-content a {
  background: url("../images/arrow_down.png");
  display: inline-block;
  padding: 15px 44px 37px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
  background-position: 50% 79%;
  background-size: 10px;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #333;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
} */

.sub-menu-content a {
  /* padding: 10px 20px 15px; */
  /* padding: 50px 50px; */
  color: #333;
  font-size: 16px;
  /* background-color: #fff; */
  /* letter-spacing: 1px; */
  text-decoration: none;
  text-transform: uppercase;
}

.sub-menu-border {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.down-button-content {
  position: relative;
}

.sub-menu-content .down-button {
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* cursor: pointer; */
  position: absolute;
  top: 10px;
}

.sub-menu-border:last-child {
  border-right: none;
}

/* .sub-menu-content a:last-child {
  border-right: none;
} */

.sub-menu-content .down-button:hover {
  top: 80px;
  text-decoration: none;
}

.sub-menu-content a:focus {
  text-decoration: none;
  color: #333;
}

.sub-menu-content a:hover {
  text-decoration: none;
  color: #333;
}

.section-top {
  background-image: linear-gradient(0deg, #171717, transparent 30%),
    url("../images/blog-section-background.jpg");
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  background-color: #171717;
  background-position: 0 0, 0 0;
  background-size: auto, cover;
  text-align: center;
}

.blog-content-header h3 {
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 30px;
}

.section-arrow {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -30px;
  border-top: 20px solid #171717;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
}

.blog-detail-section {
  /* padding-top: 100px; */
  /* padding-bottom: 100px; */
  background-color: #fff;
  text-align: left;
}

/* .blog-detail-section p {
  color: #333;
  font-size: 1rem;
  line-height: 28px;
  font-weight: 400;
} */

.arrow-cursor {
  cursor: pointer;
}

.blog_programs {
  padding-top: 85px;
  background-color: #f5f5f5;
}

.other_program {
  position: relative;
  margin-top: 60px;
  padding: 28px 26px 60px;
  background-color: #ffb422;
}

.other_program .container {
  margin-top: -100px;
}

.row-blog {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.shortcut {
  margin-bottom: 20px;
}

.blog-desc1 {
  background: #fff;
  border-radius: 13px;
  box-shadow: rgb(255 255 255 / 10%) 0px 1px 1px 0px inset,
    rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  position: relative;
  text-align: justify;
}

@media (max-width: 760px) {
  .sub-menu-border {
    border-right: none;
    border-bottom: 1px solid #eee;
    /* display: block; */
    padding-bottom: 10px;
  }
}

@media (max-width: 660px) {
  .stands-for-title .title-wraper {
    margin-top: -80px;
  }

  .title-description p {
    font-size: 1rem !important;
  }

  .stands-for-title .title-wraper {
    max-width: 100%;
    text-align: justify;
  }

  .stands-for-blog {
    height: 80vh;
  }
}

@media only screen and (max-width: 400px) {
  .post-title {
    font-size: 0.8rem;
    line-height: 15px;
    background-color: #17171762;
    padding: 1rem;
  }

  .title-wraper h1 {
    font-size: 1.5rem;
  }

  .title-description p {
    font-size: 0.5rem;
    line-height: 1.5rem;
  }

  .stands-for-blog {
    height: 50vh;
  }
}