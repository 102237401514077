@import url('https://fonts.googleapis.com/css?family=Oswald|Roboto:400,700');
 .title {
	 font-size: 3rem;
	 margin-bottom: 0.5em;
     font-family: 'Oswald', sans-serif;
}
 .container {
	 max-width: 1024px;
	 width: 90%;
	 margin: 0 auto;
}
 .timeline-item {
	 padding: 3em 2em 2em;
	 position: relative;
	 color: #000000b3;
	 border-left: 6px solid #ffb422;
     font-family: 'Oswald', sans-serif;
	 box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
	 margin: .5rem 0;
}
 .timeline-item p {
	 font-size: 1rem;
}
 .timeline-item::before {
	 content: attr(date-is);
	 position: absolute;
	 left: 2.5em;
	 font-weight: bold;
	 top: 1em;
	 display: block;
	 font-family: 'Roboto', sans-serif;
	 font-weight: 700;
	 font-size: 0.785rem;
}
 .timeline-item::after {
	 width: 20px;
	 height: 20px;
	 display: block;
	 top: .7em;
	 position: absolute;
	 left: -12px;
	 border-radius: 10px;
	 content: '';
	 border: 2px solid #0000004d;
	 background: #ffb422;
     box-shadow: 0 0 0 4px #fff, inset 0 2px 0 #00000014, 0 3px 0 4px #0000000d;
}
 .timeline-item:last-child {
	 border-image: linear-gradient(to bottom, #ffb422 60%, #ffb52267) 1 100%;
}
