.hero-section {
  width: 100%;
  height: 100vh;
}
.slick-dots {
  top: 45%;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translateY(-50%);
}
.slick-dots li button:before {
  font-size: 15px;
  line-height: 15px;
  color: #ffb422;
}

@-webkit-keyframes moveToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes moveToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes moveToUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes moveToUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.moveToLeft {
  -webkit-animation-name: moveToLeft;
  animation-name: moveToLeft;
}

.slick-active .slide-content h3 {
  animation-name: moveToLeft;
  animation-duration: 1s;
  opacity: 1;
  animation-delay: 0.3s;
}

.slick-active .slide-content p {
  animation-name: moveToLeft;
  animation-duration: 1s;
  opacity: 1;
  animation-delay: 0.4s;
}

.slick-active .slide-content .slider-btn {
  animation-name: moveToUp;
  animation-duration: 1s;
  opacity: 1;
  animation-delay: 0.5s;
}

.hero-bg1 {
  background: linear-gradient(10deg, #00224fd7, #44444355),
    url(../images/hero-banner.jpg);
}

.hero-bg2 {
  background: linear-gradient(10deg, #00224fd7, #44444355),
    url(../images/hero-banner2.jpg);
}

.hero-bg3 {
  background: linear-gradient(10deg, #00224fd7, #44444355),
    url(../images/hero-banner3.jpg);
}

.hero-bg1,
.hero-bg2,
.hero-bg3 {
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
}

.slider-description {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 85%;
  margin: 0 auto;
  color: white;
  cursor: text;
}

.slider-description h3 {
  font-size: 3.8rem;
  text-shadow: 1px 1px 2px gray;
  font-family: "Merriweather", serif;
}

.slider-description p {
  width: 45%;
  font-size: 18px;
}

.donate-cards {
  position: relative;
  height: 280px;
  overflow: hidden;
}

/* .primary-btn:hover .donate-cards img:hover {
  transform: scale(1.2);
} */
.donate-cards img {
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  transition: all 0.5s ease-in;
  z-index: 10;
}

.donate-cards img:hover {
  transform: scale(1.2);
}

.span-color2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2730366e;
}

.card-description {
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  padding: 0 12px;
  color: white;
}

.card-description h3 {
  font-size: 2rem;
  text-shadow: 1px 1px 2px gray;
}

.registration-cover {
  background: linear-gradient(15deg, #ffb522d7, #00224fcc),
    url("../images/registration.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.reg-description {
  padding: 5.5rem;
}

.reg-description h5 {
  color: rgb(121, 121, 121);
}

.reg-description p {
  color: rgb(173, 173, 173);
}

.reg-description button {
  outline: none;
  border: 1px solid #ffb422;
  background: none;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.1rem;
  color: #ffb422;
  transition: 0.3s ease-in;
}

.reg-description button:hover {
  color: #111;
  background: #ffb422;
}

.registration-cover h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: bold;
  background-image: url(../images/registration.jpg);
  background-position: center;
  color: transparent;
  -webkit-background-clip: text;
  font-family: "Roboto", sans-serif;
}

.our-info {
  position: relative;
}

.our-info img {
  width: 100%;
  height: 100%;
}

.our-info span {
  position: absolute;
  width: 65%;
  height: 30px;
  bottom: -15px;
  left: 0;
  background: #ffb422;
  z-index: -1;
}

.our-info::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80px 80px 0;
  border-color: transparent #ffb422 transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
}

.our-info-img {
  width: 100px;
  height: 100px;
  background: #f3a916;
  border-radius: 50%;
  margin: 0 auto;
  text-align: center;
}

.our-info-img img {
  width: 50%;
  height: 50%;
}

.hexagon {
  position: relative;
  display: inline-block;
  margin: 6px 23px;
  background-color: #f3a916;
  text-align: center;
}

.hexagon,
.hexagon::before,
.hexagon::after {
  width: 90px;
  height: 145px;
  border-radius: 12%/5%;
}

.hexagon::before {
  background-color: inherit;
  content: "";
  position: absolute;
  left: 0;
  transform: rotate(-60deg);
}

.hexagon::after {
  background-color: inherit;
  content: "";
  position: absolute;
  left: 0;
  transform: rotate(60deg);
}

.hexagon:nth-child(even) {
  top: 80px;
  background-color: #273036;
}

.hexagontent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140%;
  font-size: 1.4rem;
  line-height: 1.2;
  z-index: 5;
}

.ibws-fix {
  font-size: 0;
}

.honeycomb {
  margin: 0 auto;
  text-align: center;
}

.our-description {
  border-bottom: 1px solid rgb(158, 157, 157);
}

.our-description:last-child {
  border-bottom: none;
}

.goal-cover {
  background: linear-gradient(10deg, #1c2327d3, #273036ab),
    url("../images/developmentCover.jpg");
  background-position: center;
  background-size: cover;
}

/* Hexagons */
#hexGrid {
  overflow: hidden;
  width: 60%;
  margin: 0 auto;
  padding: 0.866% 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
}

#hexGrid:after {
  content: "";
  display: block;
  clear: both;
}

.hex {
  position: relative;
  list-style-type: none;
  float: left;
  overflow: hidden;
  visibility: hidden;

  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
  transform: rotate(-60deg) skewY(30deg) translatez(-1px);
}

.hex * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hexIn {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #273036;
  background: #f1a918;
  overflow: hidden;
  transform: skewY(-30deg) rotate(60deg);
}

/*** HEX CONTENT **********************************************************************/

.hex h2,
.hex p {
  margin: 0;
  width: 102%;
  left: -1%;
  /* prevent line on the right where background doesn't cover image */
  padding: 5%;
  box-sizing: border-box;

  font-weight: 300;
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
}

.hex h2:hover,
.hex p:hover {
  color: #273036;
}

.hex h2 {
  bottom: 50%;
  padding-top: 30%;
  font-size: 1.5em;
  z-index: 1;
}

.hex h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.hex p {
  top: 50%;
  padding-bottom: 50%;
}

/*** HOVER EFFECT  **********************************************************************/

.hexIn:hover h1,
.hexIn:hover p {
  color: #273036;
}

/*** SPACING AND SIZING *****************************************************************/

@media (min-width: 1201px) {

  /* <- 2-3  hexagons per row */
  .hex {
    width: 32.666%;
    /* = (100-2) / 3 */
    padding-bottom: 37.72%;
    /* =  width / sin(60) */
  }

  .hex:nth-child(5n + 1),
  .hex:nth-child(5n + 2) {
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }

  .hex:nth-child(5n + 3),
  .hex:nth-child(5n + 4),
  .hex:nth-child(5n + 5) {
    margin-top: -8.564%;
    margin-bottom: -8.564%;
  }

  .hex:nth-child(5n + 2),
  .hex:nth-child(5n + 4) {
    margin-right: 1%;
    margin-left: 1%;
  }

  .hex:nth-child(5n + 1) {
    margin-left: 0.5%;
  }

  .hex:nth-child(5n + 3),
  .hex:nth-child(5n + 6) {
    clear: left;
  }
}

@media (max-width: 1200px) {

  /* <- 1-2  hexagons per row */
  .hex {
    width: 49.5%;
    /* = (100-1) / 2 */
    padding-bottom: 57.158%;
    /* =  width / sin(60) */
  }

  .hex:nth-child(3n + 1) {
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }

  .hex:nth-child(3n + 2),
  .hex:nth-child(3n + 3) {
    margin-top: -13.423%;
    margin-bottom: -13.423%;
  }

  .hex:nth-child(3n + 1) {
    margin-left: 0.5%;
  }

  .hex:nth-child(3n + 3) {
    margin-left: 1%;
  }

  .hex:nth-child(3n + 2),
  .hex:nth-child(3n + 4) {
    clear: left;
  }
}

/* End Hexagons */

.middle-section {
  margin-top: 6rem;
}

.cards-section2 {
  width: 87%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.card-area2 {
  background: linear-gradient(10deg, #1c232798, #27303685),
    url(../images/activity.jpg);
  background-position: center;
  background-size: cover;
  width: 93%;
  margin: 0 auto;

  padding: 8.2rem 0;
  box-shadow: 3px 3px 20px #273036a9;
  position: relative;
  transform: translateY(-40px);
  transition: 0.5s linear;
}

.card-area2:nth-child(2) {
  background: linear-gradient(10deg, #1c232798, #27303685),
    url(../images/activity2.jpg);
  background-position: center;
  background-size: cover;
}

.card-area2:nth-child(3) {
  background: linear-gradient(10deg, #1c232798, #27303685),
    url(../images/activity3.jpg);
  background-position: center;
  background-size: cover;
}

.card-area2:nth-child(4) {
  background: linear-gradient(10deg, #1c232798, #27303685),
    url(../images/activity4.jpg);
  background-position: center;
  background-size: cover;
}

.card-area2 h2 {
  font-size: 2rem;
  color: #fff;
}

.card-area2 .card-arrow {
  position: absolute;
  bottom: 5%;
  font-size: 1.2rem;
  right: 15px;
  display: none;
  cursor: pointer;
}

.card-area2:hover {
  transform: translateY(-55px);
  box-shadow: 3px 15px 30px #273036a9;
}

.card-area2:hover .card-arrow {
  display: block;
}

.who-we-are-desc h3 {
  font-size: 23px;
}

.who-we-are-desc p {
  color: #606162;
}

@media only screen and (min-width: 1570px) {
  .donate-cards {
    height: 350px;
  }

  .card-description h4 {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }

  .card-area2 h2 {
    font-size: 3rem;
  }

  .card-area2 {
    padding: 10.5rem 0;
  }
}

@media only screen and (max-width: 1400px) {
  .who-we-are-desc p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1134px) {
  .registration-cover h2 {
    font-size: 3rem;
  }

  .who-we-are-desc p {
    font-size: 11px;
  }

  .who-we-are-desc h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1024px) {
  .reg-description {
    padding: 4rem;
  }

  .hexagon:nth-child(even) {
    top: 0;
    background-color: #273036;
  }
}

@media only screen and (max-width: 991px) {
  .hexagon:nth-child(even) {
    top: 0;
  }

  .paddinng-top {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 768px) {
  .reg-description {
    padding: 3rem;
  }

  .registration-cover h2 {
    font-size: 2rem;
  }

  .our-info-desc h3 {
    font-size: 20px;
  }

  .our-info-desc p {
    font-size: 10px;
  }

  .card-area2 h2 {
    font-size: 1.2rem;
  }

  #hexGrid {
    width: 100%;
  }

  .slider-description h3 {
    font-size: 2.5rem;
  }

  .slider-description p {
    width: 75%;
  }

  .our-info-img {
    width: 60px;
    height: 60px;
  }

  .who-we-are-desc h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .reg-description {
    padding: 3rem;
  }

  .registration-cover h2 {
    font-size: 4rem;
  }

  .registration-cover {
    width: 100%;
    height: 300px;
  }
}

@media only screen and (max-width: 660px) {
  .cards-section2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-area2 {
    margin-bottom: 2rem;
  }

  .card-area2 h2 {
    font-size: 1.5rem;
  }

  .hexagon:nth-child(even) {
    top: 80px;
  }

  .hex h2 {
    bottom: 65%;
    padding-top: 20%;
    font-size: 1.5em;
  }

  .hex p {
    top: 25%;
    padding-bottom: 1%;
  }

  .our-info-img {
    text-align: center;
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }

  .our-info-img img {
    width: 70%;
  }

  .who-we-are-title {
    text-align: center;
  }
}

@media only screen and (max-width: 425px) {
  .registration-cover h2 {
    font-size: 2.5rem;
  }

  .registration-cover {
    height: 250px;
  }

  .reg-description h2 {
    font-size: 20px;
  }

  .reg-description p {
    font-size: 13px;
  }

  .reg-description button {
    padding: 10px 25px;
  }

  .cards-section2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .card-area2 {
    margin-bottom: 2.2rem;
  }

  .card-area2 h2 {
    font-size: 1.7rem;
  }

  .slider-description h3 {
    font-size: 2rem;
  }

  .slider-description p {
    width: 100%;
  }

  .hex h2 {
    bottom: 70%;
    padding-top: 20%;
  }

  .hex p {
    top: 28%;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 375px) {
  .hex h2 {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 320px) {
  .hexagon:nth-child(even) {
    top: 0;
  }
}

/* Campaign Section CSS  Start  */
.section-bgg {
  background-color: rgb(238, 238, 238);
}

.campaignImg {
  width: 100%;
  height: 250px;
  transition: 0.5s ease-in-out;
}

.campaignImg:hover {
  transform: scale(1.1);
}

.joinBGLink {
  background-color: black;
}

.joinCampaignLink {
  color: #fff;
  text-decoration: none;
}

.joinCampaignLink:hover {
  color: #ffb422;
}

/* help the people card section css */
.help-the-people-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #e6e6e6;
  padding: 2rem;
  margin-bottom: 0.5rem;
}

.cardDetailEclipseHeme {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* after 3 line show ... */
  -webkit-box-orient: vertical;
  width: fit-content;
  block-size: fit-content;
}

.read-detail:hover {
  color: #273036;
}

/* help the people card section css End */
.campaignButton {
  width: 100%;
  border: none;
  outline: none;
  background: #ffb422;
  padding: 12px 16px;
  /* border: 1px solid #ffb422; */
  color: #273036;
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.5s ease;
  text-decoration: none;
  display: inline-block;
}

.campaignButton:hover {
  background-color: #ffb422;
  color: #ffffff;
}

/* Campaign Section CSS  End  */