/* Get Certificate Start =================== */
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=The+Nautigal&display=swap');

.certificate-banner {
  /* background: #ce8a03; */
  overflow: hidden;
  padding: 7rem 0;
}

.certificate-title {
  font-family: 'Satisfy', cursive;
}

.form-certificate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-certificate input {
  width: 75%;
  border: 1px solid #e9aa2c;
}

.get-certificate-btn {
  text-decoration: none;
  outline: none;
  border: 1px solid #ffb422;
  background: none;
  padding: 8px 40px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #212529;
  transition: 0.3s ease-in;
}

.get-certificate-btn:hover {
  color: #fff;
  background: #ffb422;
}

.get-certificate-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.certificate-design {
  position: relative;
}

.z-index {
  z-index: 5;
}

.certificate-design::before {
  position: absolute;
  content: "";
  left: -13px;
  top: -13px;
  height: 32%;
  width: 15%;
  background: #212529;
}

.certificate-design::after {
  position: absolute;
  content: "";
  right: -13px;
  bottom: -13px;
  height: 32%;
  width: 15%;
  background: #212529;
}

.get-certificate-right {
  background-color: #fff;
}

.get-certificate-inner {
  margin: 3rem 0;
}


/* Get Certificate End =================== */



/* Download certificate Start =================== */
.download-certificate {
  height: 1130px;
  width: 790px;
  background-color: #fff;
  padding: 2rem;
  border: 10px solid #d3d3d3;
  margin: auto;
  overflow: scroll;

  /* To Display None Comment Out These Three Line */
  /* position: absolute;
  left: -1000px;
  top: -1000px; */
}

.certificate-info li span {
  color: red;
}

.signature {
  font-family: 'The Nautigal', cursive;
  position: relative;
}

.z-in {
  z-index: 1000;
}

.user-image {
  width: 100%;
  z-index: 1000;
  border: 10px solid red;
}

.signature:after {
  content: "";
  position: absolute;
  left: 25%;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #000;
}

.certificate-user {
  height: 100px;
  width: 100px;
}

.barCode {
  height: 50px;
  width: 200px;
  margin: auto;
}

.barCode img {
  width: 100%;
}

.certificate-user img {
  width: 100%;
}

/* Download certificate End =================== */