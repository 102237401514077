.about-us-content {
    background: #fff;
    transform: translateY(-12%);
    border-radius: 13px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    position: relative;
    text-align: justify;
    margin-top: 3.5rem;
    padding: 0 0px;
}

.about-banner{
    background: url("../../images/whsc-logo.png");
    background-position: center;
    background-repeat: repeat;
    height: 100%;
    width: 100%;
    z-index: -1;
}



@media only screen and (max-width: 425px) {
    .about-banner {
    padding-bottom: 200px;
    align-items: center;
   
    }
    .about-us-content p{
        padding: 1rem;
    }
}
    