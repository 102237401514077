@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap");

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: #ffb422;
  outline: none;
}

.heading-font {
  font-family: "Merriweather", serif;
}

.content-font {
  font-family: "Roboto", sans-serif;
}

::selection {
  background-color: #ffb422;
  color: #000;
}

p {
  font-family: "Roboto", sans-serif;
  /* font-size: 1rem !important; */
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
}

button:focus,
button:active,
input:focus,
input:active {
  outline: none;
  box-shadow: none;
}

.top-gap {
  padding-top: 4.5rem;
}

.bottom-gap {
  padding-bottom: 4.5rem;
}

.custom-container {
  width: 85%;
  margin: 0 auto;
}

.primary-color {
  color: #ffb422;
}

.primary-bg {
  background: #ffb422;
}

.primary-color2 {
  color: rgb(231, 135, 9);
}

.activities {
  background: #ffb422;
}

.secondary-color {
  color: #9e9e9e;
}

.dark-color {
  background: #273036;
}

.dark-color2 {
  color: #273036;
}

.primary-btn {
  border: none;
  outline: none;
  background: transparent;
  padding: 10px 30px;
  border: 2px solid #ffb422;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transition: 0.5s ease;
  cursor: pointer;
}

.primary-btn:hover {
  background-color: #ffb422;
  color: #273036;
}

.primary-btn2 {
  border: none;
  outline: none;
  background: transparent;
  padding: 10px 25px;
  border: 2px solid #ffb422;
  color: #273036;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.primary-btn2:hover {
  background-color: #ffb422;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-200 {
  padding-top: 200px;
}

.themeBg2 {
  background-color: #d1e8e4;
}

.chat {
  /* position: absolute; */
  right: 0;
  bottom: 0;
  height: 100px;
  width: 100px;
  background-color: #ffb5225b;
  z-index: 999;
  position: fixed;
}

.heading-style3 {
  text-align: center;
  position: relative;
  width: 34%;
  margin: 0 auto;
  margin-bottom: 3.2rem;
  /* border: 1px solid #ffb422; */
}

.heading-style3::before {
  position: absolute;
  content: "";
  left: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 20%;
  width: 21%;
  height: 40%;
  margin: auto 0;
}

.heading-style3::after {
  position: absolute;
  content: "";
  right: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 20%;
  width: 21%;
  height: 40%;
  margin: auto 0;
}

.heading-style3 h2 {
  color: #333;
  font-size: 35px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.heading-style3 h2::before {
  position: absolute;
  content: "";
  left: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 14%;
  height: 40%;
  margin: auto 0;
}

.heading-style3 h2::after {
  position: absolute;
  content: "";
  right: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 14%;
  height: 40%;
  margin: auto 0;
}

.heading-style4 {
  text-align: center;
  position: relative;
  width: 45%;
  margin: 0 auto;
  margin-bottom: 3.2rem;
}

.heading-style4::before {
  position: absolute;
  content: "";
  left: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 20%;
  width: 15%;
  height: 40%;
  margin: auto 0;
}

.heading-style4::after {
  position: absolute;
  content: "";
  right: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 20%;
  width: 15%;
  height: 40%;
  margin: auto 0;
}

.heading-style4 h2 {
  color: #fff;
  font-size: 35px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.heading-style4 h2::before {
  position: absolute;
  content: "";
  left: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 11%;
  height: 40%;
  margin: auto 0;
}

.heading-style4 h2::after {
  position: absolute;
  content: "";
  right: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 11%;
  height: 40%;
  margin: auto 0;
}

.blog-para::first-letter {
  color: #ffb422;
  float: left;
  font-family: Georgia;
  font-size: 75px;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

.blog-para{
  font-size: 1rem !important;
}

.error {
  font-size: 10rem;
  text-shadow: 2px 2px 5px #ffb422;
}

.errorImg {
  width: 10%;
}

.uploadBtn{
  border: none;
  outline: none;
}

.uploadBtn:hover{
  background-color: #989898;
}

@media only screen and (max-width: 1199px) {
  .heading-style3::before {
    width: 22%;
  }

  .heading-style3::after {
    width: 22%;
  }

  .heading-style3 h2::before {
    width: 13%;
  }

  .heading-style3 h2::after {
    width: 13%;
  }

  .heading-style4::before {
    width: 17%;
  }

  .heading-style4::after {
    width: 17%;
  }

  .heading-style4 h2::before {
    width: 13.5%;
  }

  .heading-style4 h2::after {
    width: 13.5%;
  }

  .heading-style3 {
    width: 45%;
  }

  .heading-style4 {
    width: 60%;
  }
}

@media only screen and (max-width: 999px) {
  .heading-style3 h2 {
    font-size: 25px;
  }

  .heading-style4 {
    width: 85%;
  }
}

@media only screen and (max-width: 690px) {
  .heading-style3 h2 {
    font-size: 20px;
  }

  .heading-style4 h2 {
    font-size: 20px;
  }

  .error {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 425px) {
  .heading-style3 h2 {
    font-size: 25px;
  }

  .heading-style3 {
    width: 75%;
  }

  .heading-style4 h2 {
    font-size: 22px;
  }

  .heading-style4 {
    width: 75%;
  }

  .heading-style4 {
    width: 100%;
  }

  p {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 320px) {
  .heading-style3 {
    width: 95%;
  }

  .heading-style4 h2 {
    font-size: 20px;
  }
}

/* Contact Bar Css file */
.TopSubFooterBg {
  background-color: #FF9F22;
}

.TopSubFooter {
  padding: 3rem 0rem;
  color: #fff;
}

.TopSubFooter button {
  outline: none;
  border: none;
  background: #fff;
  border: 2px solid #fff;
  color: #161616;
  padding: 10px 30px;
  transition: .5s;
  border-radius: 5px;
  float: right;
}

.TopSubFooter button:hover {
  background: none;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .TopSubFooter {
    text-align: center;
  }

  .TopSubFooter button {
    margin-top: 1rem;
    float: none;
  }
}