.accordion-button:not(.collapsed) {
  background-color: #ffb422 !important;
  color: #fff !important;
  box-shadow: none !important;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordionn {
    width: 90%;
  }
}