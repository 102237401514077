.globalMemberCard {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: .5s ease-in-out;
    overflow: hidden;
}

.global-img-box {
    position: relative;
    height: 23rem;
    border:10px solid#8e816f;
    border-radius: 15px;
    /* border-image:   linear-gradient(to right, grey 25%, yellow 25%, yellow 50%,red 50%, red 75%, teal 75%) 5; */
    border-image: linear-gradient(to right, red 20%, green 20%, green 40%, blue 40%, blue 60%, maroon 60%, maroon 80%, chocolate 80%);
}

.global-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;  
    
}

.teamImg{
  width: 100%;
  position: relative;
}

.global-team-content {
    max-width: 100%;
    border-radius: 5px;
    background-color: #273036c6;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 0.5rem 0;
}

.global-card-details {
    height: 0;
    opacity: 0;
    transition: .5s ease-in-out;
}

.globalMemberCard:hover .global-card-details {
    height: 100px;
    opacity: 1;
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* Director Card Start ================== */
.director-card{
  margin: 10px;
  box-shadow: 0px 15px 40px #eee;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.director-card .bgg-image img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  clip-path: polygon(0 0,100% 0%,100% 70%,45% 100%,0 70%);
}


.director-card .picc img{
  height: 250px;
  width: 250px;
  border-radius: 50%;
  border: 10px solid #fff;
  padding: 0;
  margin-top: -165px;
  position: relative;
  transition: 1s;
  overflow: hidden;
}

.director-card:hover>.picc>img:hover{
  transform: scale(1.1);
}

.infoo{
  padding: 1rem 0;
}

.director-card .infoo h4{
  color: #273036;
  margin-top: .5rem;
}


.infoo{
    position: relative;
    transition: .7s;
}

.infoo::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4%;
  background-color: #ff9616;
  transition: .7s;
}

.infoo:hover::before{
  height: 65%;
  background-color: #626668c8;
}

.infoo p{
  transition: 1.2s;
  font-size: .8rem;
  padding: 0 1rem;
}

.card-overlay{
  position: absolute;
  opacity: 0;
  color: #fff;
  top: 50%;
  left: 10px;
  height: 0;
  transition: 1.2s;
}

.card-overlay:hover{
  opacity: 1;
}

.infoo:hover .card-overlay{
  opacity: 1;
}

.card-overlay:hover::before{
  /* height: 100%; */
  opacity: 1;
}
/* Director Card End ================== */

/* Senior Advisor Card Start =============== */
.senior-advisor-team-content {
    max-width: 90%;
    border-radius: 5px;
    background-color: #273036;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    padding: 1rem 0;
    margin: 0 auto;
    cursor: pointer;
    border-top: 5px solid #ff9616;
}

.senior-img{
  border-bottom: 5rem solid #333B41;
}

/* Senior Advisor Card End =============== */

/* ProtectionDesk Start ============ */

.protection-info::before{
  height: 5%;
  background-color: #273036;
}

/* ProtectionDesk End ============ */

/* AdmissionUnit Start =========== */
.admission-single-card{
  position: relative;
  width: 100%;
  height: 450px;
  /* background: #273036; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 5px;
  overflow: hidden;
   transition: 1s;
}
.admission-single-card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff9616;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}
.admission-single-card:hover:before{
  clip-path: circle(300px at 80% -20%);
}
.admission-single-card:after{
  content: 'WHSC';
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(255,255,25,0.05);
}
.admission-single-card .imgBx{
  position: absolute;
  top: 30%;
  transform: translateY(-30%);
  width: 100%;
  height: 80%;
  transition: 0.5s;
}

.admission-single-card:hover .imgBx{
  top: 0%;
  transform: translateY(0%);
}

.imgBx img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 10px;
}
 .contentBx{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;
  background-color: #273036ec;
  padding: 1rem;
}

.contentBx h2{
  font-weight: 600;
  color: #fff;
}

.admission-card-overlay{
  opacity: 0;
  height: 0;
  width: 100%;
  color: #fff;
  z-index: 200;
  transition: 1s ease-in-out;
}

.card-designation{
  line-height: 5px;
  margin: 1rem 0;
}

.admission-single-card:hover .admission-card-overlay{
  opacity: 1;
  height: auto;
  width: 100%;
   z-index: 10;
}

/* AdmissionUnit End =========== */

.patron-info{
  background-color: #273036;
  color: #fff;
}

.country-committee-info{
  background-color: #161616;
  color: #fff;
  border-top: 2px solid #fff;
}

@media only screen and (max-width: 992px) {
  .director-card .picc img{
  height: 120px;
  width: 120px;
  margin-top: -90px;
}
.director-card .bgg-image img{
  width: 100%;
  height: 150px;
}
.infoo p{
  font-size: .5rem;
}
}

@media only screen and (max-width: 750px) {
  .director-card .picc img{
  height: 200px;
  width: 200px;
  margin-top: -90px;
}
.director-card .bgg-image img{
  width: 100%;
  height: 200px;
}
.infoo p{
  font-size: 1rem;
}
}



/* Manual Call To Action */
.TopSubFooterBg{
  background-color: #FF9F22;
}

.TopSubFooter{
  padding: 3rem 0rem;
  color: #fff;
}

.TopSubFooter button{
  outline: none;
  border: none;
  background: #fff;
  border: 2px solid #fff;
  color: #161616;
  padding: 10px 30px;
  transition: .5s;
  border-radius: 5px;
  float: right;
}

.TopSubFooter button:hover{
  background: none;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .TopSubFooter{
  text-align: center;
}
.TopSubFooter button{
  margin-top: 1rem;
  float: none;
}
}
/* Manual Call To Action */