
.swal2-styled.swal2-confirm {
  background: #ffb422 !important;
  background-color: #ffb422 !important;
  border: none;
  outline: none;
}

.event-img-fluid {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.apply-for-desc {
  padding: 40px;
}
.apply-btn {
  padding: 10px;
  margin-bottom: 15px;
  background: #fff;
  transition: 0.5s ease;
}
.apply-btn-desc {
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
}

.apply-btn:hover {
  background: #273036;
  color: #fff;
}

.event-color h3 {
  color: #273036;
}

.my-modal {
  width: 60vw;
  max-width: 60vw !important;
}

.link-color {
  color: #ffaa00;
}

@media only screen and (max-width: 1024px) {
  .apply-for-desc h3 {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 768px) {
  .apply-for-desc {
    padding: 20px;
  }
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 425px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}
