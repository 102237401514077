.heading-campaign {
  text-align: center;
  position: relative;
  width: 65%;
  margin: 0 auto;
  margin-bottom: 3.2rem;
  /* border: 1px solid #ffb422; */
}

.heading-campaign::before {
  position: absolute;
  content: "";
  left: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 35%;
  width: 26%;
  height: 40%;
  margin: auto 0;
}

.heading-campaign::after {
  position: absolute;
  content: "";
  right: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 35%;
  width: 26%;
  height: 40%;
  margin: auto 0;
}

.heading-campaign h2 {
  color: #333;
  font-size: 40px;
  text-align: center;
  /* margin: 0 30px; */
  text-transform: uppercase;
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.heading-campaign h2::before {
  position: absolute;
  content: "";
  left: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 20%;
  height: 40%;
  margin: auto 0;
}

.heading-campaign h2::after {
  position: absolute;
  content: "";
  right: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 20%;
  height: 40%;
  margin: auto 0;
}

.runningCampaign {
  width: 100%;
  border: none;
  outline: none;
  background: #ffb422;
  padding: 12px 16px;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.5s ease;
  text-decoration: none;
  display: inline-block;
}

.runningCampaign:hover {
  background-color: #ffb422;
  color: #273036;
}

/* ALL Campaign Section CSS  */

/* #animation {
  transition: 1s;
}

#animation:hover {
  transform: scale(1.08);
  z-index: 1;
} */

.campaignCard {
  background: transparent;
}


.single-campaign-card {

  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.card-content {
  background-color: #D1E8E4;
}

.campaignImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.campaignImg:hover {
  transform: scale(1.08);
}


.showCampaign {
  width: 100%;
  border: none;
  outline: none;
  background: #ffb422;
  padding: 12px 16px;
  border: 1px solid #ffb422;
  color: #273036;
  font-weight: bold;
  font-size: 1.2rem;
  /* border-radius: 10px; */
  transition: 0.5s ease;
  text-decoration: none;
  display: inline-block;
}

.showCampaign:hover {
  background-color: #fff;
  color: #273036;
}

/* Campaign Section CSS  */
.heading-campaign {
  text-align: center;
  position: relative;
  width: 65%;
  margin: 0 auto;
  margin-bottom: 3.2rem;
  /* border: 1px solid #ffb422; */
}

.heading-campaign::before {
  position: absolute;
  content: "";
  left: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 35%;
  width: 26%;
  height: 40%;
  margin: auto 0;
}

.heading-campaign::after {
  position: absolute;
  content: "";
  right: 0;
  border-bottom: 2px solid #ffb422;
  bottom: 35%;
  width: 26%;
  height: 40%;
  margin: auto 0;
}

.heading-campaign h2 {
  color: #333;
  font-size: 40px;
  text-align: center;
  /* margin: 0 30px; */
  text-transform: uppercase;
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.heading-campaign h2::before {
  position: absolute;
  content: "";
  left: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 20%;
  height: 40%;
  margin: auto 0;
}

.heading-campaign h2::after {
  position: absolute;
  content: "";
  right: 0;
  border-top: 2px solid #ffb422;
  bottom: 15%;
  width: 20%;
  height: 40%;
  margin: auto 0;
}

.runningCampaign {
  width: 100%;
  border: none;
  outline: none;
  background: #273036;
  padding: 12px 16px;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.5s ease;
  text-decoration: none;
  display: inline-block;
}

.campaign-body {
  background-color: #D1E8E4;
}

.runningCampaign:hover {
  background-color: #ffb422;
  color: #273036;
}

/* home Campaign Section CSS  Start  */
.section-bg {
  background-color: rgb(39, 48, 54);
}


.campaignImg {
  width: 100%;
  height: 250px;
  transition: .5s ease-in-out;

}

.campaignImg:hover {
  transform: scale(1.1);
}

.joinBGLink {
  background-color: black;
}

.joinCampaignLink {
  color: #fff;
  text-decoration: none;
}

.joinCampaignLink:hover {
  color: #ffb422;
}

.campaignHead {
  background-color: #273036;
  color: rgb(255, 255, 255);
  font-size: 1.5em;
  font-weight: bold;
}

.campaignButton {
  width: 100%;
  border: none;
  outline: none;
  background: #ffb422;
  padding: 12px 16px;
  /* border: 1px solid #ffb422; */
  color: #273036;
  font-weight: bold;
  font-size: 1.2rem;
  transition: 0.5s ease;
  text-decoration: none;
  display: inline-block;
}

.campaignButton:hover {
  background-color: #ffb422;
  color: #ffffff;
}

.cardDetailEclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* after 3 line show ... */
  -webkit-box-orient: vertical;
}

.sectionBG {
  background-color: #EEEEEE;
}

.loaderBg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #273036;
  padding: 200px 0;
  position: relative;
}

.loaderBg::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #ffb422;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderBg span{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .5rem;
  align-items: center;
}

@media only screen and (max-width: 400px) {

}