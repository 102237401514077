.freebirdFormviewerViewHeaderTitle .exportFormTitle .freebirdCustomFont {
  display: none !important;
}

.contact-container {
  width: 90%;
  margin: 0 auto;
}
.heading-style {
  width: 370px !important;
  height: 50px;
  border-bottom: 5px solid #ffb422;
  line-height: 50px;
  position: relative;
  overflow: hidden;
}

.heading-style2 {
  width: 370px !important;
  height: 50px;
  border-bottom: 5px solid #ffb422;
  line-height: 50px;
  position: relative;
  overflow: hidden;
}

.heading-style span,
.heading-style2 span {
  color: #fff;
  font-size: 1.5rem;
  background: #ffb422;
  display: inline-block;
  padding: 0 20px;
  font-family: "Oswald", sans-serif;
  /* position: relative; */
}

.heading-style span::before {
  position: absolute;
  content: "";
  width: 57%;
  height: 100%;
  background: #ffb422;
  z-index: -1;
  transform: rotate(45deg);
}

.heading-style2 span::before {
  position: absolute;
  content: "";
  width: 60%;
  height: 100%;
  background: #ffb422;
  z-index: -1;
  transform: rotate(45deg);
}

.contactForm input,
.contactForm textarea {
  border: 1px solid rgb(192, 191, 191);
  border-radius: 5px;
  background: #eee;
  color: rgb(88, 87, 87);
  outline: none;
}

.submit-btn {
  padding: 10px 25px;
  border: none !important;
  background: #ffb422 !important;
  color: #fff !important;
  border-radius: 20px !important;
  font-size: 1.2rem;
  transition: 0.3s linear;
}

.submit-btn:hover {
  background: #111 !important;
  transform: translateX(5px);
}
.map-section {
  background: #eee;
}

.errTextContact{
  background-color: #eee;
  color: orangered;
  padding: .5rem;
  border-radius: 5px;
  display: inline-block;
}


@media screen and (min-width: 1570px) {
  .heading-style2 span::before {
    width: 75%;
  }
}
@media screen and (max-width: 768px) {
  .heading-style {
    width: 260px !important;
  }

  .heading-style2 {
    width: 260px !important;
  }
  .heading-style span::before {
    width: 68%;
  }

  .heading-style2 span::before {
    width: 86%;
  }
  .contact-container {
    width: 95%;
    margin: 0 auto;
  }
  .office-desc small {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .office-desc small {
    font-size: 1.2rem;
  }
}
.office-desc span {
  font-size: 1.2rem;
}
