.blog-banner-sec2 {
  background: url(../images/Pakistan.jpg);
  background-size: cover;
  background-position: fixed;
  height: 80vh;
  position: relative;
}
.blog-social-icon {
  color: #ffb422;
}
.blog-social-icon:hover {
  color: #273036;
}

.para-heading{
  font-weight: bold;
  font-size: 20px;

}

