.main-image {
  position: relative;
}

.main-image .bannerImg {
  position: relative;
  overflow: hidden;
}

.bannerImg img {
  height: 70vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}

.overlay {
  width: 100%;
  height: 70vh;
  background-image: linear-gradient(#000000b2, #000000b0);
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
}

.main-image .bannerImg img {
  width: 100%;
}

.banner-content,
.banner-content2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.banner-content span {
  color: #fff;
  border-radius: 5px;
}

.banner-content p {
  color: #d4d4d4;
  padding: 0.5rem 0 0.5rem 0;
  font-size: 1.2rem;
}

.bannerBtn {
  text-decoration: none;
  color: #273036;
  background-color: #ffb422;
  padding: 10px 20px;
  border-radius: 5px;
}

.bannerBtn:hover {
  color: #fff;
  background-color: #eb8500;
}

/* Banner 2 */
.banner-content h1,
.banner-content2 h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #fff;
}

.banner-link a {
  font-size: 1em;
  text-decoration: none;
}

.banner-link a:hover {
  color: #fff;
}

/* Banner 2 End  */

@media only screen and (max-width: 768px) {
  .banner-content h1 {
    font-size: 1.5rem;
    margin-top: 2rem;
  }

  .banner-content {
    top: 60%;
  }

  .banner-content p {
    padding: 0.2rem 0;
    font-size: 1.2rem;
  }

  .bannerBtn {
    padding: 5px 10px;
    border-radius: 5px;
  }

  .bannerImg img {
    height: 60vh;
  }

  .overlay {
    height: 60vh;
  }
  .banner-link a {
    font-size: 0.5em;
  }
}

@media only screen and (max-width: 400px) {
  .banner-content h1,
  .banner-content2 h1 {
    font-size: 1.5rem;
    margin-top: 1em;
  }

  .banner-content p {
    font-size: 0.5rem;
    padding: 0 0;
  }

  .banner-content {
    top: 60%;
  }

  .banner-content {
    top: 50%;
    left: 4%;
    transform: translate(-4%, -50%);
  }
}
