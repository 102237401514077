/* overlay menu */

.overlay-menu {
  z-index: 100;
  background-color: #273036;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: -430%;
  right: 0;
  transition: 2400ms;
  font-family: "Oswald", sans-serif;
}
.overlay-burger {
  cursor: pointer;
}
.overlay-menu.active-sidebar {
  top: 0;
  transition: 1000ms;
}
.side-menu-content h4::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 5px;
  width: 40px;
  height: 2px;
  background: #fff;
}

.side-nav-list .nav-list {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: white;
  font-size: 1rem;

  opacity: 0;
  transition: all 0.8s ease 500ms;
  transform: translateY(30px);
}
.side-nav-list .nav-list:first-child {
  transition-delay: 0.7s;
}
.side-nav-list .nav-list:nth-child(2) {
  transition-delay: 0.9s;
}
.side-nav-list .nav-list:nth-child(3) {
  transition-delay: 0.11s;
}
.side-nav-list .nav-list:nth-child(4) {
  transition-delay: 0.13s;
}
.side-nav-list .nav-list:nth-child(5) {
  transition-delay: 0.15s;
}
.side-nav-list .nav-list:nth-child(6) {
  transition-delay: 0.16s;
}

.overlay-menu.active-sidebar .side-nav-list .nav-list {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s eases;
}
.nav-list:hover {
  color: #ffb422;

  transform: translateX(5px) !important;
}
.border-design {
  border: 1px solid #0ae0b8;
}
.overlay-heading {
  position: relative;
  font-size: 2rem;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #ffb422;
  width: 100%;
}

.box-info {
  background: #323d44;
  transition: 0.3s ease;
  cursor: pointer;
  padding: 1.2rem;
}
.box-info:hover {
  background: #2e373d;
}
.box-info h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.close-btn {
  margin-right: 7.5%;
  cursor: pointer;
  transition: 0.5s;
}
.close-btn:hover {
  color: #fff;
}
.hr-color {
  background: #89898b;
}
.social-icons-style small a:hover {
  color: #ffb422;
}
@media screen and (max-width: 1450px) {
  .heading-gap {
    padding: 1rem 0;
    padding-bottom: 1.5rem;
  }
  .overlay-heading {
    position: relative;
    font-size: 1.5rem;

    width: 100%;
  }
  .side-menu-content h4 {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1500px) {
  .heading-gap {
    padding: 2rem 0;
  }
  .side-menu-container {
    padding: 2rem 0;
  }
}
@media screen and (min-width: 1570px) {
  .heading-gap {
    padding: 2.5rem 0;
  }
  .side-menu-container {
    padding: 2rem 0;
  }
  .box-info {
    padding: 1.5rem;
  }
  .sidemenu-footer {
    padding-top: 1rem;
  }
}
@media screen and (max-width: 900px) {
  .overlay-menu,
  .overlay-burger {
    display: none;
  }
}
