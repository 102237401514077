.header {
  position: fixed;
  top: 0px;
  background-color: #00000098;
  width: 100%;
  z-index: 100;
}

.nav-container {
  width: 86%;
  margin: 0 auto;
}

.header .nav {
  position: relative;
  display: flex;
  /* max-width: calc(100% - 200px); */
  margin: 0 auto;
  height: 70px;
  align-items: center;
  justify-content: space-between;
}
.nav .content {
  display: flex;
  align-items: center;
}
.nav .content .links {
  position: absolute;
  right: 0;
  display: flex;
}
.logo img{
  padding-bottom: 0.8rem;
  position:relative;

}
/* .header.sticky .logo img {
  padding-top: 27px;
  position: relative;
} */
/* .header.sticky.links {
 padding-bottom:60px;
  
} */
.content .logo a {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.content .links li {
  list-style: none;
  line-height: 70px;
}
.content .links li a,
.content .links li label {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 12px;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: "Oswald", sans-serif;
}
.content .links li label {
  display: none;
}
.content .links li a:hover,
.content .links li label:hover {
  color: #eee;
}
/* .header .search-icon, */
.header .menu-icon {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  line-height: 70px;
  width: 70px;
  text-align: center;
}
.header .menu-icon {
  display: none;
}

.header.sticky {
  background-color: #ff9f22;
}

.header.sticky .icon-down,
.header.sticky .logo,
.header.sticky ul li a {
  color: #273036;
}

.header.sticky .donate {
  color: #fff !important;
}

.dropdown-area {
  background-color: #00000098 !important;
  position: relative;
  margin: 0;
  padding: 0;
  position: relative;
}

.dropdown-area::before {
  content: " ";
  position: absolute;
  left: 20%;
  transform: translate(-50%);
  top: -10px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.dropdown-area li {
  border-bottom: 1px solid #ffb522c2;
  position: relative;
}

.dropdown-area li:hover {
  background-color: #ffb422;
}

.dropdown-area li a:hover {
  transform: translateX(5px);
}

.dropdown-area li:last-child {
  border-bottom: none;
}

.dropdown-area li a {
  color: #fff !important;
}

/* ////////////////  */
.content ul li a {
  position: relative;
}
.content ul li a:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(50%) scale(0);
  -webkit-transform: translateX(-50%) scaleX(0);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  width: 60%;
  height: 1px;
  background-color: #d98c00;
  -webkit-transition: 250ms;
  transition: transform 450ms;
}
.content ul li a:hover:after {
  transform: translateX(-50%) scaleX(1);
}

.content ul li a:hover {
  color: #fff;
}

.donate {
  color: #d98c00 !important;
  position: relative;
}

.donate::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 80%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.burger {
  margin-left: 0rem;
}

.header input[type="checkbox"] {
  display: none;
}
/* Dropdown Menu code start */
.content .links ul {
  position: absolute;
  background: #171c24;
  top: 80px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.content .links li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}
.content .links ul li a {
  display: block;
  width: 100%;
  line-height: 30px;
}
.content .links ul ul {
  position: absolute;
  top: 0;
  right: calc(-100% + 8px);
}
.content .links ul li {
  position: relative;
}

.content .links ul li:hover ul {
  top: 0;
}

.item-form-side-bar{
  display: none;
}

.icon-down{
  color: #ffb422;
}

/* Responsive code start */
@media screen and (max-width: 1250px) {
  .header .nav {
    max-width: 100%;
    padding: 0 20px 0 0;
  }
  .nav .content .links {
    margin-left: 5px;
  }
  .content .links li a {
    padding: 8px 13px;
  }
  .content .links li a,
  .content .links li label {
    font-size: 12px;
  }
}
@media screen and (max-width: 900px) {
  .donate::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
  }
  .content .links li a,
  .content .links li label {
    font-size: 12px;
    font-weight: 400;
  }
  .content .links li{
    border-bottom: 1px solid #3a3a3a;
    padding: 0 5%;
  }
  .header .menu-icon {
    display: block;
  }
  .header {
  background-color: #161616;
}
  .nav .content .links {
    display: block;
    position: fixed;
    background: #161616;
    height: 100%;
    width: 100%;
    top: 70px;
    left: -100%;
    margin-left: 0;
    max-width: 60%; /*width of sidebar */
    overflow-y: auto;
    padding-bottom: 100px;
    transition: all 0.5s ease;
  }
  .nav #show-menu:checked ~ .content .links {
    left: 0%;
  }
  .content .links li {
    margin: 10px 0px;
  }
  .content .links li a,
  .content .links li label {
    line-height: 15px;
    font-size: 14px;
    display: block;
    cursor: pointer;
  }


.header.sticky .logo,
.header.sticky ul li a {
  color: #fff;
}

  .dropdown-area li a {
  font-size: 14px !important;
  padding: 0 !important;
}

.nav-container {
  width: 100%;
  margin: 0 auto;
}

  .content .links li a.desktop-link {
    display: none;
  }
  /* dropdown responsive code start */
  .content .links ul,
  .content .links ul ul {
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    max-height: 0px;
    overflow: hidden;
  }
  .content .links #show-drop1:checked ~ ul,
  .content .links #show-drop2:checked ~ ul,
  .content .links #show-items:checked ~ ul {
    max-height: 100vh;
  }
  .content .links
  {
    padding: 0%;
  }
  .content .links ul li a {
    font-size: 1rem;
    line-height: 30px;
  }
  .dropdown-area {
  width: 98%;
}
  .item-form-side-bar{
  display: block;
}
.item-form-side-bar a{
  text-decoration: none;
  color: #fff;
}

.item-form-side-bar a:hover{
  color: #ff9f22;
}
.dropdown-icon-mobile{
  float: right;
}
}
@media screen and (max-width: 768) {
  
}

@media screen and (max-width: 550) {

}

.side-bar-title{
  color: #273036;
}

.side-bar-social-icon a{
  margin: 0 .2rem;
  font-size: 1.2rem;
}

