.org-background {
  background: #fff;
}

.org-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 60px;
}

.org-card {
  width: 83%;
  height: 330px;
  margin: 0 auto;
  border-radius: 10px;
  /* background: #141414; */
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  z-index: 50;
  transition: 0.5s ease-in;
  cursor: pointer;
}

.org-card:hover{
  box-shadow: #484c5033 0px 8px 24px;
  /* background: #272525; */
}

.org-img-sec {
  width: 100%;
  height: 50%;
}

.org-img-sec img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.org-desc h3 {
  color: #ffb422;
  text-transform: uppercase;
}

.org-desc p {
  color: rgb(223, 222, 222);
  font-size: 15px;
}
@media only screen and (max-width: 1024px) {
  .org-desc h3 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 992px) {
  .org-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .org-container {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 60px;
  }

  .org-desc h3 {
    font-size: 1.2rem;
  }

  .org-desc p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .org-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .org-card {
    height: 280px;
  }
}
