.info {
  display: flex;
  justify-content: space-between;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus {
  box-shadow: none;
}

.formDesign {
  margin: 2rem 0;
  box-shadow: 0px 15px 40px #eee;
  padding: 1rem 2rem;
}

.membership-form {
  transform: translateY(-10%);
}

.button {
  height: 60px;
  color: #fff;
  background-color: #ffb422;
  border: none;
  border-radius: 5px;

}

.button:hover {
  color: #273036;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #ffb422;
}

@media only screen and (max-width: 992px) {
  .info {
    flex-direction: column;
  }

  .formDesign input {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .membership-form {
    transform: translateY(0);
  }
}