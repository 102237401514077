polygon {
  fill: #ffb422;
  transform: rotate(0deg, 10, 15);
}


.border-section {
  display: grid;
  place-items: center;
  min-height: 150px;
  background-color: #ffb422;
}

.gallerySection img {
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  transition: 0.2s ease;
}

.gallerySection img:hover {
  transform: scale(1.2);
}

.dot {
  background-color: #ffb422 !important;
}

.dot:hover,
.active {
  background-color: #ffb422 !important;
}

.carousel .slide .legend {
  background: none !important;
}

.caption {
  transition: all 0.5s ease-in-out;
  display: inline-block;
  background: hsl(0, 0%, 0%, 0.2);
  padding: 1rem;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
}

@media only screen and (max-width: 500px) {
  .border-section {
    display: grid;
    place-items: center;
    min-height: 100px;
    background-color: #ffb422;
  }
}

.carosel-image-size {
  width: 100% !important;
  height: 80vh !important;
  object-fit: cover !important;
  object-position: center center;
}

.spinner-padding {
  padding: 10rem;
}

.spinner-border {
  width: 3rem !important;
  height: 3rem !important;
  color: #ffb422 !important;
}

.shareButtons {
  background-color: rgba(0, 0, 0, 0.8) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 20px;
  color: white;
  padding: 1px;
  font-size: 90%;
  z-index: 1000;
}

.share-section {
  font-family: "Helvetica Neue", Verdana, Helvetica, Arial, sans-serif;
  direction: ltr;
  display: block;
  opacity: 1;
  text-align: left;
  z-index: 94034;
}

.sh-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: opacity 0.2s ease-in, top 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in, top 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in, top 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in, top 0.2s ease-in;
  transition: opacity 0.2s ease-in, top 0.2s ease-in;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin-right: 8px;
  padding: 0 4px;
  position: relative;
  text-align: center;
  top: 0;
  vertical-align: top;
  white-space: nowrap;
}

.sh-button:hover {
  opacity: 0.8;
  top: -4px;
}

.st-sticky-share-buttons {
  z-index: 100 !important;
}