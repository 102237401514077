.help-the-people-details {
    position: relative;
  }
  
  .help-the-people-details h2 {
    font-size: 3rem;
    position: relative;
  }
  
  .help-the-people-details h2::after {
    position: absolute;
    content: "";
    bottom: -10px;
    width: 30%;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    background: #ffb422;
  }
  .donateBtn{
    background: #ffb422;
    color: rgb(255, 255, 255);
    border: none;
    padding: 10px 15px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
  }