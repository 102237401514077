@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');


@font-face {
  font-family:'Dancing Script', cursive;
  src: url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
}
.handwrite{
  font-family: 'Dancing Script', cursive;
  font-size:1rem;
}

h1,h2,h3,h4,h5,h6{
  font-family: "Oswald", sans-serif;
}

.pt-50{
  padding-top: 50px;
}

.about-title {
  text-align: center;
}

.about-title h2 {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.about-title h2:after {
  right:-1.2em;
}

.about-title h2:before {
  left:-1.2em;
}

.about-title h2:before,
.about-title h2:after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 5px; width: 30px;
  background-color: #ff9616;
  top: 50%;
  transform: translateY(-50%);
}

.team-card{
    box-shadow: 0px 15px 40px #eee;
    padding: 25px;
    position: relative;
    z-index: 1;
    color: #333;
    margin: .5rem;
    border: none !important;
    position: relative;
    font-family: "Oswald", sans-serif;
    transition: .5s linear;
    overflow: hidden;
}

.team-card:hover{
  transform: translateY(-5px);
  background-color: #D1E8E4 !important;
}

.team-card small{
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 3rem;
  font-weight: bold;
  color: #191a1949;
}

.shape{
  position: absolute;
  top: -1rem;
  right: -2rem;
  font-weight: bold;
  background-color: #ff96168c;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.team-card::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ff9616;
  width: 0;
  height:2px;
  transition: .5s ease-out;
}

.team-card:hover::before{
  width: 100%;
}

.team-card a{
  text-decoration: none;
  color: #161616;
}

.team-card a:hover{
  color: #005c4d;
}

.banner-box{
  box-shadow: 0px 15px 40px #eee;
  padding: 5rem;
  background-color: #fff;
  margin: 0 auto;
}

.banner-box h2{
  padding: 2rem 0;
}

.banner-box p{
  font-size: 1.2rem;
}

.box-arrow {
  position: relative;
}

.box-arrow::after {
  content: " ";
  position: absolute;
  right: 50%;
  transform: translate(50%);
  bottom: -35px;
  border-right: 35px solid transparent;
  border-left: 35px solid transparent;
  border-top: 35px solid #ff9616;
}

/* Board Of Director */
.board-member{
  padding: 2rem 2rem 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .5rem;
  align-items: center;
  place-items: center;
  place-content: center;
}


/* ---- */
.boardMemberCard{
  margin: 0 1rem;
  box-shadow: 0px 15px 40px #eee;
  transition: .7s ease-in-out;
  position: relative;
}

.boardMemberCard:hover{
  box-shadow: 0px 25px 40px #7a7a7a55;
}

.card-area::before{
  content: "";
  position: absolute;
  background: #161616ab;
  bottom: 0%;
  left: 0%;
  height: 0%;
  width: 100%;
  transition: .5s;
  z-index: -1;
}

.card-area:hover::before{
height: 100%;
}

.team-content small{
  font-size: 1rem;
}

.card-area{
  position: absolute;
  color: #fff;
  top: 5%;
  left: 5%;
  height: 90%;
  width: 90%;
  opacity: 0;
  transition: 1s;
  z-index: 1;
}

.team-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.team-content h4{
  font-size: 2rem;
}

.boardMemberCard:hover .card-area{
  opacity: 1;
}
/* Board Of Director */

@media only screen and (max-width: 992px) {
  .team-content small{
  font-size: .5rem !important;
  line-height: -5rem;
}

.team-content h4{
  font-size: 1rem;
}

.team-content p{
  font-size: .5rem;
}
}
@media only screen and (max-width: 768px) {
  .board-member{
  grid-template-columns: repeat(2, 1fr);
}
.boardMemberCard small{
  font-size: 1rem;
}
.banner-box{
  padding: .5rem;
  margin: 0 auto;
}
.banner-box p{
  font-size: 12px;
}
.banner-box h2{
  padding: 0 0;
}
}

@media only screen and (max-width: 400px) {
   .board-member{
  grid-template-columns: repeat(1, 1fr);
}
}
