
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.donate-frame{
    margin: 5rem 0;
    background-color: #F4F9F9;
    border-radius: 1%;
}

.donor-details{
    display: flex;
    flex-direction: column;
}

.donor-details input,
.donor-info input
{
    margin: .5rem 0;
    width: 100%;
    border: 1px solid #ddd;
    outline: none;
    padding: 10px 20px;
    background-color: #FBFBFB;
}

.card-with-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    margin: .2rem 0;
    padding: .5rem;
    transition: .5s;
}

.card-with-title:hover{
    background-color: #ffb422;
    cursor: pointer;
}

.card-with-title img{
    width: 20%;
}

.card-with-title h4{
    margin: 0 2rem;
}

.confirm-payment{
    width: 100%;
    outline: none;
    border: 1px solid #ddd;
    background-color: #ffb422;
    padding: 10px 20px;
    text-transform: uppercase;
}

.confirm-payment:active{
    background-color: #FF9F22;
}

.subscribe{
    display: flex;
    align-items: center;
}

.BankTypo{
    color: rebeccapurple;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@media only screen and (max-width: 768px) {

}


@media only screen and (max-width: 400px) {
.amount button{
    height: 50px;
    width: 50px;
    font-size: 12px;
}

}