.footer-content {
  text-align: justify;
  font-size: 0.5em;
}

.footer-description {
  width: 95%;
  border-bottom: 1px solid rgba(22, 22, 22, 0.623);
  /* margin: 0 20px; */
  padding-bottom: 10px;
}

.description-card {
  border-right: 1px solid rgba(22, 22, 22, 0.623);
}

.description-card:last-child {
  border-right: none;
}

.description-list {
  font-size: 0.5em;
  transition: 0.3s ease-in-out;
}

.description-list li a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}

.description-list:hover {
  transform: translateX(10px);
  text-shadow: 2px 2px 5px #ffb422;
}

.footer-left-box {
  box-shadow: 2px 2px 15px rgba(22, 22, 22, 0.623);
}

.footer-left {
  width: 85%;
  /* border: 1px solid yellow; */
}

.social-icons .icons-style {
  padding: 10px 12px;
  /* width: 5rem;
    height: 5rem; */
  /* border: 1px solid red; */
  background: #ffb422;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 5px 5px 10px rgba(22, 22, 22, 0.623);
}

.fb-padding {
  padding: 10px 14px;
  background: #ffb422;
  border-radius: 50%;
  margin-right: 15px;
  box-shadow: 5px 5px 10px rgba(22, 22, 22, 0.623);
}


.sub-footer {
  background: #273036f1;
  color: rgb(206, 204, 204);
  /* border-top: 1px solid #3f4041; */
}

.sub-footer a {
  text-decoration: none;
  color: rgb(206, 204, 204);
}

.sub-footer p {
  font-size:10px !important;
}

.sub-footer a:hover {
  color: #ffb422;
}

.credit-line {
  color: #ffb422 !important;
}

.top-footer {
  width: 85%;
  margin: 6rem auto;
}

.donate-desc {
  border-right: 2px solid #ffb422;
}

.border-gradient {
  border-style: solid;
  border-width: 8px;
  border-image: linear-gradient(to right, #ffb422, #273036) 1;
}

.primary-btn3 {
  border: none;
  outline: none;
  background: #ffb422;
  padding: 12px 55px;
  border: 1px solid #ffb422;
  color: #273036;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 10px;
  /* transition: 0.5s ease; */
}

.primary-btn3:hover {
  background-color: #fff;
  color: #273036;
}

.reg-desc {
  width: 95%;
}

@media only screen and (max-width: 1123px) {
  .footer-description {
    width: 90%;
  }

  .footer-left {
    width: 85%;
  }
}

@media only screen and (max-width: 932px) {
  .footer-description {
    width: 100%;
  }

  .footer-left {
    width: 100%;
  }

  .description-card h5 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-description {
    width: 100%;
  }

  .footer-left {
    width: 100%;
  }

  .social-icons small {
    padding: 8px;
  }

  .description-card ul li {
    font-size: 12px;
  }

  .description-card h5 {
    font-size: 15px;
  }

  .social-icons {
    padding: 1.5rem 0;
  }

  .fb-padding {
    padding: 10px 15px;
    margin-right: 9px;
  }

  .primary-btn3 {
    font-size: 0.9rem;
  }

  .social-icons {
    /* padding: 2.4rem 0; */
    /* padding-bottom: -0.5rem; */
    padding-top: 2.5rem;
  }

  .reg-desc {
    width: 100%;
  }
}

@media only screen and (max-width: 762px) {
  .footer-description {
    border-bottom: none;
  }

  .footer-left {
    margin: 10px 50px;
    text-align: center;
  }

  .footer-container {
    width: 95%;
    margin: 0 auto;
  }

  .description-card {
    border-right: none;
  }

  .description-card ul li {
    font-size: 16px;
  }

  .description-card h5 {
    font-size: 20px;
  }

  .copyright-nav {
    font-size: 0.5rem;
  }

  .donate-desc {
    border-right: none;
  }
}

@media only screen and (max-width: 600px) {
  .social-icons {
    /* padding: 2.4rem 0; */
    /* padding-bottom: -0.5rem; */
    padding-top: 1rem;
  }

  .footer-left p {
    text-align: justify;
  }

  .social-icons p {
    text-align: center;
  }
}