/* @import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=The+Nautigal:wght@700&display=swap');

.blog-banner-sec {
  height: 80vh;
  position: relative;
}

.blog-banner-heading {
  position: relative;
}

.blog-banner-heading h2 {
  font-size: 3rem;
  position: relative;
}

.blog-banner-heading h2::after {
  position: absolute;
  content: "";
  bottom: -10px;
  width: 15%;
  height: 5px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffb422;
}

.blog-desc {
  background: #fff;
  transform: translateY(-12%);
  border-radius: 13px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  position: relative;
  text-align: justify;
  margin-top: 3.5rem;
}

.blog-desc2 {
  background: #fff;
  border-radius: 13px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  position: relative;
  text-align: justify;
  margin-bottom: 3.5rem;
}

.blog-desc p {
  font-size: 18px;
}

.blog-icons {
  position: absolute;
  right: 0;
  top: 0;
}

.blog-icons small a:hover {
  color: #273036;
}

.blog-footer {
  background: linear-gradient(10deg, #ffb522a1, #eba720a8),
    url(../../images/whsc2.jpg);
  background-position: center;
  background-size: cover;
  padding: 6.5rem 0;
}

.blog-footer h3 {
  font-size: 1.8rem;
}

.blog-icons2 small a {
  color: #273036;
  font-size: 1.2rem;
}

.blog-icons2 small a:hover {
  color: #fff;
}

.blog-bottom {
  background: #ffb422;
  margin-top: 4rem;
  border-radius: 0 0 13px 13px;
}

.share-text {
  /* font-family: "Homemade Apple", cursive; */
  font-family: 'The Nautigal', cursive;
  font-size: 3rem;
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .share-text {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .blog-desc {
    transform: translateY(-8%);
  }
  .share-text {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 660px) {
  .blog-desc {
    transform: translateY(-6%);
  }

  .blog-banner-heading h2 {
    font-size: 2rem;
  }
  .share-text {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 360px) {
  .blog-banner-heading h2 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 320px) {
  .share-text {
    font-size: 0.8rem;
  }
}
