.counter {
  background-color: #ffb422;
  margin-top: 80px;
  margin-bottom: 80px;
}

.circle {
  width: 150px;
  height: 150px;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 5px;
}

.vision {
  margin-bottom: 80px;
}

.counter-sec {
  padding: 3rem;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: white !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #212529 !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #212529 !important;
}

.mission-vision-btn {
  text-decoration: none;
  outline: none;
  border: 1px solid #ffb422;
  background: none;
  margin-top: 1rem;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #212529;
  transition: 0.3s ease-in;
}

.mission-vision-btn:hover {
  color: #fff;
  background: #ffb422;
}

.mision-vision-description {
  font-size: 1rem;
}

@media only screen and (max-width: 769px) {
  .title {
    font-size: 1.7rem;
  }
  .mision-vision-description {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 425px) {
  .circle {
    width: 80px;
    height: 80px;
  }

  .counter-sec {
    padding: 3rem !important;
  }

  .title {
    font-size: 2rem;
  }

  .mission-vision-btn {
    width: 100%;
  }

  .margin-bottom-mv {
    margin-bottom: 5rem;
  }
}
