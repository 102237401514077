.back-to-top .top {
    position: fixed;
    cursor: pointer;
    bottom: 1rem;
    right: 1rem;
    font-size: 1rem;
    color: #161616;
    background-color: #ffb422;
    z-index: 100;
    width: 50px;
    text-align: center;
    height: 50px;
    line-height: 45px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    box-shadow: 0 0 15px 2px #d19213;
}

.back-to-top .top:hover {
    color: #ffffff;
    background: #161616;
}