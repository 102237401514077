.mission-banner {
  background: url(../images/heroSec.jpg);
  background-position: center;
  background-size: cover;
  height: 80vh;
}

.mission-banner-desc2 {
  position: absolute;
  right: 5%;
  width: 42%;
  top: 60%;
  transform: translateY(-60%);
  padding: 20px;
  background: #ffb422;
  text-align: right;
}

.mission-banner-desc2 p {
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  padding-left: 20px;
  text-align: right;
}
@media (max-width: 768px) {
  .mission-banner-desc2 p {
    font-size: 1rem;
  }
}
@media (max-width: 600px) {
  .mission-banner-desc2 {
    width: 80%;
    right: 12%;
  }
}
