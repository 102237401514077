.leftDesign {
    color: #FF9F22;
    font: 300 35px"Source Sans Pro", Georgia, serif;
    margin: 20px 0 15px -38px;
    padding: 0 0 2px 30px;
    border-left: solid 8px #FF9F22;
}

.termsPage {
    padding: 30px;
    margin-top: 30px;
    background: #fff;
    border-radius: 15px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}